<template>
    <v-container style="max-width: 1000px;">
      <v-row justify="center">
        <v-col cols="12" class="d-flex justify-center">
          <div class="responsive-container pb-5" @click="$router.push('/')">
            <img src="@/assets/logo-logo.png" alt="Logo" class="responsive-logo">
            <img src="@/assets/logo1.svg" alt="Logo Text" class="responsive-text pl-2">
        </div>
        </v-col>
      </v-row>   

      <v-row justify="center">
        <v-col cols="12" md="8">
          <v-card class="pa-5" color="#f9f9f9">
            <v-card-text class="term-definition">              
<h3>About Us</h3>              
Welcome to BJJ Dictionary, your comprehensive resource for understanding the art and language of Brazilian Jiu-Jitsu. Whether you're a seasoned practitioner or just starting out on your grappling journey, our goal is to provide clear, detailed definitions of every BJJ term you may encounter on the mats.
<br><br>
Brazilian Jiu-Jitsu is a complex and evolving martial art, and we understand how overwhelming the terminology can be. From the basics of "guard" to the intricacies of advanced techniques, this dictionary is designed to break down each concept in a way that's easy to understand, with the depth necessary to improve your knowledge and technique.
<br><br>
<h3>Our Mission</h3>
At BJJ Dictionary, we aim to be the go-to source for anyone seeking to deepen their understanding of BJJ. We believe that knowing the right terminology is key to mastering the art, communicating effectively with training partners, and gaining confidence in your grappling abilities. Our mission is to offer a user-friendly, informative platform that helps you navigate the complexities of BJJ and elevate your training experience.
<br><br>
<h3>Why We Created This Site</h3>
We created BJJ Dictionary because we’ve experienced firsthand how important it is to understand the language of Brazilian Jiu-Jitsu. From your first class to advanced training, the right knowledge can enhance your ability to learn, practice, and teach BJJ. This dictionary is not just about definitions—it's about making BJJ more accessible to everyone.
<br><br>
<h3>Support Us</h3>
BJJ Dictionary is supported by the BJJ community, and we’re committed to keeping this resource free for everyone. If you find value in what we offer, consider supporting us through <a href="https://www.paypal.com/donate/?hosted_button_id=3QBMVFF8CQAUS">donations</a> to help maintain and expand our site.
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    
    <v-bottom-navigation bg-color="#eaecf0">
      <v-btn value="home"  :to="{ path: '/' }">
          <v-icon>mdi-home-outline</v-icon>
          <span>Home</span>
        </v-btn>

        <v-btn value="a2z" :to="{ path: '/a-z' }">
          <v-icon>mdi-order-alphabetical-ascending</v-icon>
          <span>List</span>
        </v-btn>

        <v-btn value="FAQ" :to="{ path: '/faq' }">
          <v-icon>mdi-frequently-asked-questions</v-icon>
          <span>FAQ</span>
        </v-btn>

        <v-btn value="donate" href="https://www.paypal.com/donate/?hosted_button_id=3QBMVFF8CQAUS" target="_blank">
          <v-icon>mdi-hand-heart-outline</v-icon>
          <span>Donate</span>

        </v-btn>
      </v-bottom-navigation>


</template>

<script>


export default {
  data() {
    return {
      searchQuery: '',

    };
  },
  async created() {
  
  },
  computed: {
   

  },
  methods: {
 
  },

};
</script>

<style scoped>
.responsive-container {
  cursor: pointer;
  display: flex;
  flex-direction: row; /* Display images side by side */
  align-items: center;
  justify-content: center; /* Center the images horizontally */
  width: 80%;
  max-width: 600px;
  height: auto;
}

.responsive-logo {
  max-width: 20%; /* Adjust as needed to fit your layout */
  height: auto;
}

.responsive-text {
  max-width: 70%; /* Adjust as needed to fit your layout */
  height: auto;  
}

.term-definition {
  font-family: sans-serif;
    font-size: 0.9em;
}
</style>
