<template>
  <div v-if="!termsLoaded && !termLoaded && !isTermsDataLoaded" class="loading-container">
    <v-progress-circular indeterminate color="primary"></v-progress-circular><br> 
    Loading new terms...
  </div>
  <v-app v-else :class="backgroundClass">
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-snackbar v-model="snackbar.show" :timeout="snackbar.timeout" color="success">
      {{ snackbar.message }}
    </v-snackbar>
  </v-app>
</template>

<script>
import { useMainStore } from '@/stores/mainStore';
import { mapState } from 'pinia';
import { useRouter } from 'vue-router';

export default {
  name: 'App',
  data() {
    return {
      backgroundClass: 'default-background',
      store: useMainStore(),
    };
  },  
  methods: {
    isCurrentRoute(routeName) {
      return this.currentRouteName === routeName;
    }
  },  
  created() {
    const store = useMainStore();
    const router = useRouter();

    router.beforeEach((to, from, next) => {
      // Set the background class based on the route name
      if (to.name === 'Term') { this.backgroundClass = 'gray-background'; console.log("beforeEach")} 
      if (to.name === 'Home') { this.backgroundClass = 'default-background'; } 
      if (to.name === 'FAQ') { this.backgroundClass = 'default-background'; } 
      if (to.name === 'About') { this.backgroundClass = 'default-background'; } 
      next();
    });    

    store.fetchTerms(); // Fetch data when the app is created
    


  },
  mounted() {


  },
  computed: {
    ...mapState(useMainStore, ['termsLoaded', 'snackbar', 'terms', 'termsDataLoaded']),
    currentRouteName() {
      return this.$route.name;
    },
    termLoaded(){
      if (this.$route.name === "Term" && this.terms && this.terms[this.$route.params.id]) {
        console.log("term loaded compute changed")
        return true;
      } else {
        return false;
      }
    },
    isTermsDataLoaded() {
      if ((this.$route.name === "Home" || this.$route.name === "A2Z") && this.termsDataLoaded) {
        console.log("terms data loaded compute changed");
        return true;
      } else {
        return false;
      }
    }          

  },  
};
</script>

<style>

.default-background {
  background-color: #ffffff;
}
.gray-background {
  background-color: #f9f9f9;
}
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  text-align: center;  
}
</style>