import { defineStore } from 'pinia';
import { collection, getDocs, getFirestore, doc, setDoc, getDoc, Timestamp } from 'firebase/firestore';
import { db } from '@/firebaseConfig';
import { getFunctions, httpsCallable } from 'firebase/functions';
//import { useRouter } from 'vue-router';

export const useMainStore = defineStore('main', {
  state: () => ({
	// Define your state properties here
	counter: 0,
    terms: {}, 
    termsLoaded: false,
    termsDataLoaded: false,
    lastUpdated: null,
    progress: 0,
    snackbar: {
      show: false,
      message: '',
      timeout: 3000, // Duration in milliseconds
    },    
  }),
  actions: {
	// Define your actions here
	increment() {
	  this.counter++;
	},
  async fetchTerms() {
    const functions = getFunctions();
    const fetchTermsFunction = httpsCallable(functions, 'fetchTerms');

    //last updated code
    const lastUpdateRef = doc(db, 'config', 'lastUpdate');
    const lastUpdateDoc = await getDoc(lastUpdateRef);
    if (lastUpdateDoc.exists()) {     
      const lastUpdateTime = lastUpdateDoc.data().time;   
      console.log(`Last update timestamp: `, lastUpdateDoc.data().time.seconds);
      console.log("Current last updated time: ", this.lastUpdated);

      if (this.lastUpdated && this.lastUpdated.seconds == lastUpdateTime.seconds) {
        console.log('No new updates found.');
        return;
      }
      this.lastUpdated = lastUpdateTime;        

    } else {
      console.log('No such document!');
    }
    //fetch initial term data first
    this.fetchTermsData()

    //fetch terms code
    console.log("fetch terms")
    
      try {
        const result = await fetchTermsFunction();
        const { terms, error } = result.data;

        if (error) {
          console.error('Error fetching terms:', error);
          return;
        }

        this.terms = terms;
        this.termsLoaded = true;
        console.log('Terms loaded:', this.terms);
      } catch (error) {
        console.error('Error calling fetchTerms function:', error);
      }

    },   
    async fetchOneTerm(termName) {
      const termRef = doc(db, 'terms', termName);
      const termDoc = await getDoc(termRef);
      if (termDoc.exists()) {
        console.log('Fetch one term doc loaded:', termDoc.data());
        this.terms[termName] = termDoc.data(); 
      } else {
        console.log('Term document not loaded!');
        return null;
      }

    },
    async fetchTerms2() {
      //last updated code
      const lastUpdateRef = doc(db, 'config', 'lastUpdate');
      const lastUpdateDoc = await getDoc(lastUpdateRef);
      if (lastUpdateDoc.exists()) {     
        const lastUpdateTime = lastUpdateDoc.data().time;   
        console.log(`Last update timestamp: `, lastUpdateDoc.data().time.seconds);
        console.log("Current last updated time: ", this.lastUpdated);

        if (this.lastUpdated && this.lastUpdated.seconds == lastUpdateTime.seconds) {
          console.log('No new updates found.');
          return;
        }
        this.lastUpdated = lastUpdateTime;        

      } else {
        console.log('No such document!');
      }
      //fetch terms code
      console.log("fetch terms")
      
      try {
      const querySnapshot = await getDocs(collection(db, 'terms'));
      const totalDocs = querySnapshot.docs.length;
      let processedDocs = 0;
      
      this.terms = querySnapshot.docs.reduce((acc, doc) => {
        acc[doc.id] = doc.data();
        processedDocs++;
        //this.progress = (processedDocs / totalDocs) * 100; // Update progress percentage
        this.termsLoaded = processedDocs === totalDocs; // Set termsLoaded to true when all docs are processed
        return acc;
      }, {});

      } catch (error) {
        console.error('Error fetching terms:', error);
        // Handle the error appropriately
      }

      },    
      async fetchTermsData() {
        //This just fetches the overview and definition of the terms
        console.log("fetching terms data...")      

        const docRef = doc(db, 'config', 'termsData');
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {   
          const termsData = docSnap.data().data;
          console.log('Terms data 1:', termsData);

          // Store the termsData into this.terms
          if (Object.keys(this.terms).length === 0) {
            Object.entries(termsData).forEach(([key, value]) => {
              this.terms[key] = value;
            });
            console.log('terms data', this.terms);
            this.termsDataLoaded = true;
          }

        } else {
          console.log('No such document!');
          return
        }

        console.log('Terms data has been fetched and stored successfully.');
        
      },      
    async saveTerm(selectedTerm) {
      if (!selectedTerm || !selectedTerm.name) {
        console.error('selectedTerm is undefined or does not have a name property');
        return;
      }      
        const db = getFirestore();
        const termRef = doc(db, 'terms', selectedTerm.name);
        await setDoc(termRef, selectedTerm);
        console.log(`Term ${selectedTerm.name} has been saved successfully.`);

        // Set lastUpdate.time to the current timestamp
        const configRef = doc(db, 'config', 'lastUpdate');
        await setDoc(configRef, { time: Timestamp.now() }, { merge: true });
        console.log('Last update time has been set to the current timestamp.');

        //alert(`Term ${selectedTerm.name} has been saved successfully.`);
        this.showSnackbar(`Term ${selectedTerm.name} has been saved successfully.`, 'success') 
      },
    async addNewTerm(termName, termDoc) {
        termName = termName.toLowerCase();
        termDoc = termDoc.toLowerCase();      

        try {
          const docRef = doc(collection(db, 'terms'), termDoc);
          await setDoc(docRef, {
            term: termName,
            enabled: false,
          });

          // Set lastUpdate.time to the current timestamp
          const configRef = doc(db, 'config', 'lastUpdate');
          await setDoc(configRef, { time: Timestamp.now() }, { merge: true });
          console.log('Last update time has been set to the current timestamp.');

          //alert('New term added successfully');
          this.showSnackbar(`New term added successfully.`, 'success')           
          this.fetchTerms();
        } catch (error) {
          console.error('Error adding document: ', error);
          //alert('Failed to add new term');
          this.showSnackbar(`Failed to add new term`, 'red')           
          
        }
      },      
      showSnackbar(message, color) {
        this.snackbar.message = message;
        this.snackbar.color = color;
        this.snackbar.show = true;
      },        
      redirectToRandomTerm(router) {
       // const router = useRouter();
        //const termKeys = Object.keys(this.terms);
        const termKeys = Object.entries(this.terms)
        .filter(([, term]) => term.enabled)
        .map(([key]) => key);

        if (termKeys.length === 0) {
          console.error('No terms available to select.');
          return;
        }
        const randomKey = termKeys[Math.floor(Math.random() * termKeys.length)];
        //this.$router.push({ name: 'Term', params: { id: randomKey } });
        router.push(`/term/${randomKey}`);
      }      
  },
  persist: true,

});
