<template>
    <v-container style="max-width: 1000px;">
      <v-row justify="center">
        <v-col cols="12" class="d-flex justify-center" @click="$router.push('/')">
          <div class="responsive-container pb-5">
            <img src="@/assets/logo-logo.png" alt="Logo" class="responsive-logo">
            <img src="@/assets/logo1.svg" alt="Logo Text" class="responsive-text pl-2">
        </div>
        </v-col>
      </v-row>   

      <v-expansion-panels>
      <v-expansion-panel
        v-for="(faq, index) in faqs"
        :key="index"
      >
        <v-expansion-panel-title class="text-button">{{ faq.title }}</v-expansion-panel-title>        
        <v-expansion-panel-text>
          {{ faq.text }}
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>

    </v-container>

    
    <v-bottom-navigation bg-color="#eaecf0">
      <v-btn value="home"  :to="{ path: '/' }">
          <v-icon>mdi-home-outline</v-icon>
          <span>Home</span>
        </v-btn>

        <v-btn value="a2z" :to="{ path: '/a-z' }">
          <v-icon>mdi-order-alphabetical-ascending</v-icon>
          <span>List</span>
        </v-btn>

        <v-btn @click="redirectToRandomTerm">
          <v-icon>mdi-arrow-decision</v-icon>
          <span>Random</span>
        </v-btn>    

        <v-btn value="donate" href="https://www.paypal.com/donate/?hosted_button_id=3QBMVFF8CQAUS" target="_blank">
          <v-icon>mdi-hand-heart-outline</v-icon>
          <span>Donate</span>
        </v-btn>

      </v-bottom-navigation>


</template>

<script>
import { useMainStore } from '@/stores/mainStore';

export default {
  data() {
    return {
      searchQuery: '',
      faqs: [
        {
          title: "Do I need to be in shape to start BJJ?",
          text: "No, you don't need to be in shape to begin. BJJ helps improve your fitness over time, and many people start without prior athletic background. It's best to start training and get in shape progressively through practice."
        },
        {
          title: "What should I wear for my first BJJ class?",
          text: "For your first class, you can wear comfortable athletic clothes, like a t-shirt and shorts. Some gyms might lend you a gi (the traditional BJJ uniform), or you can buy one once you decide to continue training."
        },
        {
          title: "How often should I train as a beginner?",
          text: "Most beginners start with 2-3 classes per week. This allows your body time to recover while helping you learn consistently. As you get more comfortable, you can increase your training frequency."
        },
        {
          title: "How long does it take to earn a black belt in BJJ?",
          text: "On average, it takes 10-15 years to earn a black belt in Brazilian Jiu-Jitsu. The journey through the ranks is long because BJJ emphasizes practical skill and understanding over time, not just theoretical knowledge."
        },
        {
          title: "Is BJJ good for self-defense?",
          text: "Yes, Brazilian Jiu-Jitsu is highly effective for self-defense, especially in close-quarters situations. It teaches how to control, neutralize, and submit opponents without relying on strikes, making it ideal for real-world encounters."
        },
        {
          title: "Will I get hurt training BJJ?",
          text: "While there is always a risk of injury in any physical activity, most BJJ gyms prioritize safety and controlled training. Injuries are typically minor, like bruises or soreness, but serious injuries can be avoided by listening to your body and training partners."
        },
        {
          title: "What does 'oss' mean in BJJ?",
          text: "'Oss' is a term often used in BJJ to show respect, acknowledgment, or agreement. It’s common to hear it when greeting or thanking a training partner or instructor, but its use can vary depending on the gym culture."
        },
        {
          title: "How do I get better at BJJ?",
          text: "Consistency is key. Show up to class regularly, drill techniques, ask questions, and practice rolling (sparring) with partners of different skill levels. Watching instructional videos and studying matches can also accelerate your progress."
        },
        {
          title: "Do I need to compete in BJJ?",
          text: "No, competition is optional in Brazilian Jiu-Jitsu. Some people enjoy testing their skills in tournaments, but others train purely for fitness, self-defense, or personal growth. Competing is a personal choice, not a requirement."
        },
        {
          title: "How do I know when to tap in BJJ?",
          text: "You should tap whenever you feel trapped in a submission or uncomfortable pressure that could lead to injury. It's important to tap early to avoid getting hurt. Tapping is a normal part of learning, and it’s better to tap often than risk injury."
        },                                                                
        // Add more questions and answers here
      ]      
    };
  },
  async created() {
  
  },
  computed: {
   

  },
  methods: {
    redirectToRandomTerm() {
      const store = useMainStore();
      store.redirectToRandomTerm(this.$router);
    },

  },

};
</script>

<style scoped>
.responsive-container {
  cursor: pointer;
  display: flex;
  flex-direction: row; /* Display images side by side */
  align-items: center;
  justify-content: center; /* Center the images horizontally */
  width: 80%;
  max-width: 600px;
  height: auto;
}

.responsive-logo {
  max-width: 20%; /* Adjust as needed to fit your layout */
  height: auto;
}

.responsive-text {
  max-width: 70%; /* Adjust as needed to fit your layout */
  height: auto;  
}
</style>
