<template>
    <v-container style="max-width: 1000px;">
      <v-row justify="center">
        <v-col cols="12" class="d-flex justify-center" @click="$router.push('/')">
          <div class="responsive-container pb-5">
            <img src="@/assets/logo-logo.png" alt="Logo" class="responsive-logo">
            <img src="@/assets/logo1.svg" alt="Logo Text" class="responsive-text pl-2">
        </div>
        </v-col>
      </v-row>   

      <v-row justify="center">
      <v-col cols="12">
        <div v-for="(terms, letter) in alphabeticalTerms" :key="letter">
          <h2>{{ letter }}</h2>
            <ul>
            <li class="ml-5"  @click="navigateToTerm(term.name)" v-for="term in terms" :key="term"><span class="term-name">{{ term.term }}</span><div class="term-definition" v-html="wrapDefinition(term.definition)"></div></li>
            </ul>
        </div>
      </v-col>
    </v-row>

    </v-container>

    
    <v-bottom-navigation bg-color="#eaecf0">
      <v-btn value="home"  :to="{ path: '/' }">
          <v-icon>mdi-home-outline</v-icon>
          <span>Home</span>
        </v-btn>

        <v-btn @click="redirectToRandomTerm">
          <v-icon>mdi-arrow-decision</v-icon>
          <span>Random</span>
        </v-btn>        

        <v-btn value="FAQ" :to="{ path: '/faq' }">
          <v-icon>mdi-frequently-asked-questions</v-icon>
          <span>FAQ</span>
        </v-btn>        

        <v-btn value="donate" href="https://www.paypal.com/donate/?hosted_button_id=3QBMVFF8CQAUS" target="_blank">
          <v-icon>mdi-hand-heart-outline</v-icon>
          <span>Donate</span>

        </v-btn>
      </v-bottom-navigation>


</template>

<script>
import { useMainStore } from '@/stores/mainStore';
import { mapState } from 'pinia';

export default {
  data() {
    return {
      searchQuery: '',
   
    };
  },
  async created() {
    //const store = useMainStore();    
    //await store.fetchTerms();        




  },
  computed: {
    ...mapState(useMainStore, ['terms']),
    alphabeticalTerms() {
      const terms = this.terms;

      // Filter terms to include only those with .enabled = true
      const filteredTerms = Object.values(terms).filter(term => term.enabled);

      // Sort the filtered terms alphabetically
      const sortedTerms = filteredTerms.sort((a, b) => a.term.localeCompare(b.term));

      // Group the sorted terms by their first letter
      return sortedTerms.reduce((acc, term) => {
        const firstLetter = term.term[0].toUpperCase();
        if (!acc[firstLetter]) {
          acc[firstLetter] = [];
        }
        acc[firstLetter].push(term);
        return acc;
      }, {});
    },
    computedTerms(){
      return this.terms;
    },

  },
  methods: {
    wrapDefinition(definition) {
    if (typeof definition !== 'string') {
      return '<div id="term-definition"></div>';
    }

    // Strip HTML tags
    definition = definition.replace(/<[^>]+>/g, '');

    // Truncate if length is greater than 20 characters
    definition = definition.length > 20 ? this.truncateText(definition, 100) : definition;

    // Wrap in a div with id term definition
    return `<div id="term-definition">${definition}</div>`;
  },
    truncateText(text, maxLength) {
    if (text.length <= maxLength) {
      return text;
    }
    const truncated = text.slice(0, maxLength);
    const lastSpaceIndex = truncated.lastIndexOf(' ');
    return lastSpaceIndex === -1 ? truncated + '...' : truncated.slice(0, lastSpaceIndex) + '...';
  },    
  navigateToTerm(term) {
    if (!term) {
      return; // Exit if termName is undefined or null
    }
    this.$router.push(`/term/${term}`);
  },  
  redirectToRandomTerm() {
      const store = useMainStore();
      store.redirectToRandomTerm(this.$router);
    },  
  },

};
</script>

<style scoped>
.responsive-container {
  cursor: pointer;
  display: flex;
  flex-direction: row; /* Display images side by side */
  align-items: center;
  justify-content: center; /* Center the images horizontally */
  width: 80%;
  max-width: 600px;
  height: auto;
}

.responsive-logo {
  max-width: 20%; /* Adjust as needed to fit your layout */
  height: auto;
}

.responsive-text {
  max-width: 70%; /* Adjust as needed to fit your layout */
  height: auto;  
}

.term-name {
  font-family: 'Linux Libertine', 'Georgia', 'Times', 'Source Serif Pro', serif;
  font-size: 1.2em;
  font-weight: 400;
  color: #1E90FF;
  cursor: pointer; /* Indicate clickable item */
}

.term-name:hover {
  color: blue; /* Change color on hover */
}

.term-definition {
  font-family: sans-serif;
    font-size: 0.9em;
}
</style>
