// Import Vuetify and its styles
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import '@mdi/font/css/materialdesignicons.css'

// Import Vue and the main App component
import { createApp } from 'vue';
import App from './App.vue';
import router from './router'

// Import Pinia
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'

// Create Vuetify instance
const vuetify = createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi', // Set default icon set to Material Design Icons
    },
  })

// Create your Vue app
const app = createApp(App);

// Create Pinia instance
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)

app.use(vuetify);

app.use(router)

app.use(pinia);

// Mount your app
app.mount('#app');
